import { graphql, useStaticQuery, Link } from 'gatsby';
import React, { ReactNode } from 'react';
import 'reflect-metadata';
import Layout from '../layout';
import { INLINES, BLOCKS } from "@contentful/rich-text-types";
import { documentToReactComponents } from "@contentful/rich-text-react-renderer"
import styled from 'styled-components';
import {  Column, Container, Row, FlexProps } from '../styles/grids';

interface ContactPageProps {
    children: ReactNode;
}

// TODO: Typify
interface ContactData {
    contentfulContact: {
        contactBlurb: {
            raw: any;
        }
    };
}

const SideColumns = styled.div`
  flex: 1;
  @media only screen and (max-width: 767px) {
    flex: 0;
  }
`;

const PContact = styled.p`
    text-align: center;
    font-size: 24px;
    @media only screen and (max-width: 767px) {
        font-size: 18px;
    }
`;

const StyledLink = styled(Link)`
    text-decoration: none;
    color: #F3867F;
    &:hover{
        text-decoration: underline;
        cursor: pointer;
    }
  h1 {
      font-family: Inconsolata;
      font-weight: 200;
      font-size: 30px;
      margin: 5px;
  }
`;

const ContactPage: React.FC<ContactPageProps> = () => {

    const { contentfulContact }: ContactData = useStaticQuery(graphql`
        query {
            contentfulContact {
                contactBlurb {
                    raw
                }
            }
        }
    `);

    return (
        <Layout>
        <Container>
        <Row>
                <SideColumns/>
                <Column flex={3}>
                {documentToReactComponents(
                    JSON.parse(contentfulContact.contactBlurb.raw),
                    contact_options()
                )}
                </Column>
                <SideColumns />
        </Row>
        </Container>
    </Layout>
    );
};

const contact_options = () => {
    const option_node = {
      renderNode: {
        [BLOCKS.PARAGRAPH]: (node: any, children: any) => {
          return (
            <PContact>{children}</PContact>
          )
        },
        [INLINES.HYPERLINK]: (node: any, children: any) => {
            return (
              <StyledLink to={`https://${node.content[0].value}`} target={'_blank'}>
                  <u>{children}</u>
              </StyledLink>
            )
          },
      },
    }
    return option_node
}


export default ContactPage;

